import {
	addFallbackOwnHome,
	getHeapSessionIdCookieData,
	getFormsServiceApiDomain,
	initDuplicateSubmissionExperience,
	processFormsServiceApiSubmitSuccess,
	showNextStep,
	swapRecaptchaTokenWithFlag,
	switchFormLoadingState,
	switchFormSubmitState,
	useInitialQueryParamsFromSessionStorage,
	updateFormDataFormatForFormsService
} from '../../helpers';
/**
 * Submit form data to HS Forms Service Lead Capture API endpoint
 * @param {Object} formData - form data
 * @param {Function} successCallback - success callback
 * @param {Function} errorCallback - error callback
 */
export function submitFormDataToFormsServiceApi(formData, successCallback, errorCallback) {
	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/inquiries';

	// Add fallback OwnHome value if needed
	formData = addFallbackOwnHome(formData);

	// Remove recaptcha token string and add flag
	formData = swapRecaptchaTokenWithFlag(formData);

	// If user encounters JS error and session refreshes, send initial query params & clean up form data
	formData = useInitialQueryParamsFromSessionStorage(formData);

	// Format to new API formData structure
	const updatedFormData = updateFormDataFormatForFormsService(formData);

	window.modForm.opts.formData = updatedFormData;

	if (window.modForm.isSubmitting === false) {
		window.modForm.isSubmitting = true;
		let response = {};

		const request = new XMLHttpRequest();
		request.open('POST', apiUrl);
		request.setRequestHeader('Content-Type', 'application/json');
		request.send(JSON.stringify(updatedFormData));
		request.addEventListener('load', function() {
			const xhr = this;
			if (this.response) {
				response = { ...response, ...JSON.parse(this.response) };
				if (this.readyState === 4 && this.status === 200) {
					if ('function' === typeof successCallback) {
						successCallback(response);
					}

					if (response.CommissionDetails) {
						if (response.CommissionDetails.firePixel === true) {
							window.modForm.opts.isFirePixelTrue = true;
						}
					}

					// Needed for Frontdoor's Exclusive Match Experience
					if (response) {
						if (formData.PC) {
							response.zip = formData.PC;
						}
						if (formData.SP) {
							response.state = formData.SP;
						}

						if (formData.CT) {
							response.city = formData.CT;
						}
					}

					// E-Commerce Matching Logic (Exclusive = Redirect / Widget, Shared = HRC Secondary Button Addition)
					if (response.vendors.length > 0) {
						const exclusiveMatchKeys = Object.values(window.modForm.opts.eCommerceVendorKeys.exclusive),
							exclusiveMatch = response.vendors[0],
							exclusiveVendorKey = exclusiveMatch.vendorKey,
							sharedMatchKeys = Object.values(window.modForm.opts.eCommerceVendorKeys.shared),
							isSharedMatch = response.vendors.filter(match => sharedMatchKeys.includes(match.vendorKey));

						if (response.vendors.length === 1 && exclusiveMatchKeys.includes(exclusiveVendorKey)) {
							const matchedVendor = Object.keys(window.modForm.opts.eCommerceVendorKeys.exclusive).find(key => window.modForm.opts.eCommerceVendorKeys.exclusive[key] === exclusiveVendorKey);
							window.modForm.opts.exclusiveMatchVendor = matchedVendor;

							if (exclusiveMatch.vendorLeadDeliveryToken || (exclusiveMatch.consentIntegrationData && exclusiveMatch.consentIntegrationData.redirectUrl)) {
								window.modForm.opts.isExclusiveMatch = true;

								if (exclusiveMatch.vendorLeadDeliveryToken && exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.frontdoor) {
									const frontdoorBasesUrl = 'https://shopping.ahs.com/plans/get-my-quote/select?leadData=',
										frontdoorUtmSource = '&utm_medium=affiliate&utm_source=modernize&utm_campaign=modernize_ecomm';

									window.modForm.opts.frontdoorLeadId = exclusiveMatch.vendorLeadDeliveryToken ? exclusiveMatch.vendorLeadDeliveryToken : '';

									if (response.city && response.state) {
										window.modForm.opts.userCity = response.city;
										window.modForm.opts.userState = response.state;
									} else if (window.modForm.opts.userLocation) {
										const userLocation = window.modForm.opts.userLocation.split(',');
										window.modForm.opts.userCity = userLocation[0].trim();
										window.modForm.opts.userState = userLocation[1].trim();
									}

									const piiData = `;;${window.modForm.opts.userCity};${window.modForm.opts.userState};${response.zip};${window.modForm.opts.formData.S1};;;;;;;${window.modForm.opts.frontdoorLeadId};`;
									const piiEncoded = btoa(piiData);

									window.modForm.opts.exclusiveMatchResultsPage = `${frontdoorBasesUrl}${piiEncoded}${frontdoorUtmSource}`;
								} else if (exclusiveMatch.consentIntegrationData.redirectUrl) {
									window.modForm.opts.exclusiveMatchResultsPage = exclusiveMatch.consentIntegrationData.redirectUrl;
								}

								window.Modalytics.heap('track', ['E-Commerce Flow', {
									companyName: exclusiveMatch.vendorDisplayName,
									destinationLink: window.modForm.opts.exclusiveMatchResultsPage,
									matchCriteria: 'Exclusive (Redirect)'
								}]);
							} else if (exclusiveMatch.consentIntegrationData && (exclusiveMatch.consentIntegrationData.widgetUrl || exclusiveMatch.consentIntegrationData.additionalData) && !window.modForm.opts.includesConfirmationStep) {
								window.modForm.opts.isExclusiveWidgetMatch = true;

								let responseToken = '';

								const confirmationStep = window.modForm.opts.form.querySelector('.step--confirmation'),
									widgetStep = document.createElement('li');

								widgetStep.className = 'swiper-slide swiper-no-swiping step step--show-widget';
								widgetStep.setAttribute('aria-hidden', 'true');
								widgetStep.setAttribute('tabindex', '-1');
								widgetStep.setAttribute('data-bind', 'step');
								widgetStep.setAttribute('data-step-name', 'widget');

								const stepInner = document.createElement('div');
								stepInner.className = 'step-inner';

								widgetStep.appendChild(stepInner);

								// Needed while Triple TCPA is still being tested (removing the step if not a variation)
								if (confirmationStep) {
									confirmationStep.remove();
								}

								window.modForm.opts.form.querySelector('.swiper-wrapper').appendChild(widgetStep);
								widgetStep.setAttribute('id', `step${window.modForm.opts.form.querySelectorAll(window.modForm.opts.stepsSelector).length + 1}`);
								window.modForm.opts.formSwiperInstance.update();

								if (exclusiveMatch.consentIntegrationData.additionalData && exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.airo) {
									responseToken = JSON.stringify(exclusiveMatch.consentIntegrationData.additionalData);

									if (exclusiveMatch.consentIntegrationData.additionalData.trade && exclusiveMatch.consentIntegrationData.additionalData.campaignId) {
										const targetContainer = document.createElement('div'),
											initializeScript = document.createElement('script');

										targetContainer.id = 'airo-anchor';
										stepInner.appendChild(targetContainer);

										window.airoBookingForm = {
											zipCode: formData.zip,
											trade: exclusiveMatch.consentIntegrationData.additionalData.trade,
											firstName: formData.firstName,
											lastName: formData.lastName,
											address: formData.address,
											email: formData.email,
											phone: formData.phone,
											campaignId: exclusiveMatch.consentIntegrationData.additionalData.campaignId
										};

										initializeScript.src = `https://form.contractorappointments.com/js/embed-form.js?v=${new Date().getTime()}`;
										document.body.appendChild(initializeScript);
									}
								} else {
									responseToken = exclusiveMatch.consentIntegrationData.widgetUrl;

									if (exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.angi) {
										const dropinContainer = document.createElement('div'),
											initializeScript = document.createElement('script');

										dropinContainer.className = 'dropin-container';
										stepInner.appendChild(dropinContainer);

										initializeScript.src = 'https://request.angi.com/hcc/dropin-112a615a05e36545.js';
										initializeScript.onload = function() {
											if (window.initializeDropIn) {
												window.initializeDropIn(responseToken);
											}
										};
										document.body.appendChild(initializeScript);
									} else {
										const iframe = document.createElement('iframe');

										iframe.src = responseToken;
										stepInner.appendChild(iframe);
									}
								}

								window.modForm.opts.autoRedirectToTyOrSorryPage = false;
								switchFormLoadingState(false);
								switchFormSubmitState(false);
								showNextStep();

								if (window.modForm.opts.backButtons) {
									window.modForm.opts.backButtons.forEach(function(backButton) {
										backButton.style.display = 'none';
									});
								}
								if (window.modForm.opts.progressBar) {
									window.modForm.opts.progressBar.forEach(function(bar) {
										bar.style.display = 'none';
									});
								}

								window.Modalytics.heap('track', ['E-Commerce Widget', {
									companyName: exclusiveMatch.vendorDisplayName,
									pingToken: responseToken,
									matchCriteria: 'Exclusive (Widget)'
								}]);

								// Listen for when the widget has been completed (contains both listeners from Angi / Networx)
								window.addEventListener('message', function(event) {
									const data = event.data;

									if (data && (data.event === 'pageState' && data.payload === 'ThankYou') || (data.source === 'networx' && data.payload && data.payload.leadDone)) {
										history.pushState(null, null, `${window.modForm.opts.fullPageUrl}&refreshExp=true`);
										window.location = window.modForm.opts.resultsPage;
									}
								});
							}
						} else if (response.vendors.length >= 1 && isSharedMatch.length > 0) {
							const matchingKeys = isSharedMatch.map(match => match.vendorKey).join(',');
							window.modForm.opts.sharedParam = `shared=${matchingKeys}`;
							window.modForm.opts.isSharedMatch = isSharedMatch;
						}
					}

					// Change form submit state
					if (window.modForm.opts.showLoadingOverlay && !window.modForm.opts.isExclusiveWidgetMatch) {
						switchFormSubmitState('submitted');
						setTimeout(function() {
							processFormsServiceApiSubmitSuccess(response, xhr);
						}, 1000);
					} else {
						processFormsServiceApiSubmitSuccess(response, xhr);
					}
				} else {
					if (typeof window.modForm.opts.onGetMatchesBlocked === 'function' && this.status === 0) {
						// Status code 0 most common cause: IP Blocked
						window.modForm.opts.onGetMatchesBlocked();
					}
					// If there was an error, we first look for any error callback first
					if (typeof errorCallback === 'function') {
						errorCallback(xhr, this.status, response);
					}
				}
			}
		});

		request.addEventListener('loadend', function() {
			window.modForm.isSubmitting = false;
			switchFormLoadingState(false);
		});

		request.addEventListener('error', function() {
			console.error('Something went wrong!');

			const $loader = document.querySelector('.loader');
			if ($loader) {
				$loader.style.opacity = 0;
				$loader.style.zIndex = -1;
			}

			// Show duplicate submission fallback experience if API encounters network issues
			const heapSessionIdCookieData = getHeapSessionIdCookieData();
			initDuplicateSubmissionExperience(heapSessionIdCookieData);
			window.modUtils.heap('track', ['API Error | Showing Fallback Experience', { function: 'submitFormDataToFormsServiceApi'}]);
		});
	}
}
