/**
 * This function maps the dynamic service code value based on selected form data
 * @param {String} formData - form data
 * @returns {String} - dynamic service code value
 */
export function mapDynamicServiceCode(formData) {
	let dynamicServiceCode = '';

	const serviceCodeMapping = {
		'BATHROOM_REFACING': 'BathProjectScope',
		'CABINET_REFACING': 'service',
		'CONCRETE_FOUNDATIONS': 'service',
		'FLOORING_HARDWOOD': 'ProductService',
		'PLUMBING': 'ServiceDetails',
		'ROOFING_ASPHALT': 'ProductService',
		'SIDING_VINYL': 'ProductService',
		'WALK_IN_TUBS': 'ProductService'
	};

	const service = window.modForm.opts.vertical;
	const formProperty = serviceCodeMapping[service];

	if (service === 'BATHROOM_REFACING' && formData.BathProjectScope) {
		dynamicServiceCode = formData.BathProjectScope === 'Bathroom Remodel' ? 'BATH_REMODEL' : 'BATHROOM_REFACING';
	} else if (formProperty && formData[formProperty]) {
		dynamicServiceCode = formData[formProperty];

		if (formData.DrainageIssue && (formData.DrainageIssue === 'SLOW_DRAINAGE' || formData.DrainageIssue === 'WATER_BACKING_UP_GURGLING')) {
			dynamicServiceCode = 'SEWER';
		}
	} else {
		dynamicServiceCode = service;
	}

	return dynamicServiceCode;
}
