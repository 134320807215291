import { mapServiceSpecificAttributes } from './mapServiceSpecificAttributes';

/**
 * Updating the formData object to match required format for new HS Forms Service API
 * @param {Object} formData - Object of form data
 * @returns {Object} Processed formData
 */
export function updateFormDataFormatForFormsService(formData) {
	if (!formData) {
		return;
	}

	const { CCID, CLK, CROTag, EM, FN, HeapEventID, HEAPID, HeapSessionID, HeapUserID, HomePhoneConsentLanguage, HP, InternalSource, LN, Match, OwnHome, PartnerSourceID, PC, QTR, quadlink, S1, searchid, service, shClickKey, site, skipAddress, tagID, VWOID, VWOVariationName, TrustedFormToken } = formData;

	if (Object.keys(window.modForm.opts.serviceSpecificAttributes).length === 0) {
		mapServiceSpecificAttributes(service);
	}

	// OwnHome will be passed as a separate attribute (& not in the service specific)
	delete window.modForm.opts.serviceSpecificAttributes.OwnHome;

	const additionalRootLevelAttributes = {
		...(TrustedFormToken && {'trustedFormToken': TrustedFormToken}),
		...(PartnerSourceID && {'partnerSourceID': PartnerSourceID})
	};

	const consentCaptureData = {
		'consentMethod': 'Web',
		...(window.modForm.opts.consentRequestId && {'requestId': window.modForm.opts.consentRequestId}),
		...(window.modForm.opts.consentCaptureVendors.length > 0 ? { vendors: window.modForm.opts.consentCaptureVendors } : {})
	};

	const matchingAttributes = {
		...(Match && {'brandedVendor': Match})
	};

	const serviceSpecificAttributes = {
		...window.modForm.opts.serviceSpecificAttributes
	};

	const configOverrideAttributes = {
		...(skipAddress === 'y' && {'skipAddress': true})
	};

	const sureHitsAttributes = {
		...(searchid && {'searchid': searchid}),
		...(shClickKey && {'shclickkey': shClickKey})
	};

	const trackingAttributes = {
		...(CCID && {'ccid': CCID}),
		...(CLK && {'clk': CLK}),
		...(InternalSource && {'internalSource': InternalSource}),
		...(QTR && {'qtr': QTR}),
		...(quadlink && {'quadLink': quadlink}),
		...(tagID && {'quadTagId': tagID}),
		'site': site,
		'additionalAttributes': {
			...(HEAPID && {'HEAPID': HEAPID}),
			...(HeapUserID && {'HeapUserID': HeapUserID}),
			...(HeapEventID && {'HeapEventID': HeapEventID}),
			...(HeapSessionID && {'HeapSessionID': HeapSessionID}),
			...(VWOID && {'VWOID': VWOID}),
			...(VWOVariationName && {'VWOVariationName': VWOVariationName}),
			...(CROTag && {'CROTag': CROTag})
		}
	};

	const formattedData = {
		'zip': PC,
		'service': service,
		'firstName': FN,
		'lastName': LN,
		'email': EM,
		'phone': HP,
		'address': S1,
		'ownHome': OwnHome,
		'homePhoneConsentLanguage': HomePhoneConsentLanguage,
		'landingPageURL': window.location.href.split('#')[0],
		...additionalRootLevelAttributes,
		'consentCaptureData': {
			...consentCaptureData
		},
		...(Object.keys(matchingAttributes).length ? { matchingAttributes } : {}),
		...(Object.keys(serviceSpecificAttributes).length ? { serviceSpecificAttributes } : {}),
		...(Object.keys(configOverrideAttributes).length ? { configOverrideAttributes } : {}),
		...(Object.keys(sureHitsAttributes).length ? { sureHitsAttributes } : {}),
		...(Object.keys(trackingAttributes).length ? { trackingAttributes } : {})
	};

	return formattedData;
}
