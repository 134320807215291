import { getHeapSessionIdCookieData, initDuplicateSubmissionExperience, getPhoneAssignmentData } from '../helpers';
/**
 * Validate Zip Code
 * @param {String} zip - zip code to validate
 * @param {Function} callback - callback function after server response
 *
 */
export function isZipCodeValid(zip, callback) {
	const timeOut = window.modForm.opts.getVars && window.modForm.opts.getVars.timeout && window.modUtils.getEnv() !== 'production' ? Number(window.modForm.opts.getVars.timeout) : 3000,
		apiDomain = `https://${window.modUtils.getEnv() === 'production' ? 'hs.leadpost.net/' : 'hsleadpost1.quinstage.com/'}`,
		zipEditInput = document.querySelectorAll(window.modForm.zipEditInput)[0];
	const currentParams = window.modUtils.getUrlParamsToObject();
	const shouldCallPhoneAssignmentApi = window.modForm.opts.phoneAssignmentConfig.showPhoneAssignmentNumber && window.modForm.opts.phoneAssignmentConfig.useHsEndpoint && !currentParams.
		startStep;

	const request = new XMLHttpRequest();
	let response = {};
	request.open('GET', `${apiDomain}api/PostalCordinateInfo/${zip}/get`);
	request.timeout = timeOut;
	request.send();
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				response.success = true;
				response.data = JSON.parse(this.response);
				response.data.zip = zip;

				if (zipEditInput && zipEditInput.style.display === 'hidden') {
					document.querySelector(window.modForm.zipEditControlCls).style.display = 'block';
				}
				window.modForm.retryZipValidation = false;

				if ('function' === typeof callback) {
					callback(response);
				}

				if (shouldCallPhoneAssignmentApi) {
					getPhoneAssignmentData(window.modForm.opts.quadLinkParams, true);
				}
			} else {
				if ('function' === typeof callback) {
					if (response.status === 400) {
						callback({
							error: true,
							message: response.error_message
						});
					} else {
						callback({});
					}
				}
			}
		}
	});
	request.addEventListener('error', function() {
		console.error('Something went wrong');

		// Show duplicate submission fallback experience if API encounters network issues
		const heapSessionIdCookieData = getHeapSessionIdCookieData();
		initDuplicateSubmissionExperience(heapSessionIdCookieData);
		window.modUtils.heap('track', ['API Error | Showing Fallback Experience', { function: 'isZipCodeValid' }]);
	});
	request.addEventListener('timeout', function() {
		window.modForm.retryZipValidation = true;
		if (window.modForm.opts.cityField[0]) {
			window.modForm.opts.cityField[0].value = '';
		}
		if (window.modForm.opts.stateField[0]) {
			window.modForm.opts.stateField[0].value = '';
		}
		if (document.querySelector(window.modForm.zipEditControlCls)) {
			document.querySelector(window.modForm.zipEditControlCls).style.display = 'none';
		}
		callback({ success: true, data: { zip: zip } });
	});
}
