export function heapIdentify() {
	// Heap Identify once we get a click key value
	if (typeof window.modForm.opts.quadLinkParams.CLK !== 'undefined') {
		if ('heap' in window) {
			window.Modalytics.heap('identify', [window.modForm.opts.quadLinkParams.CLK]);
		} else {
			window.addEventListener('heapCookieResolved', function() {
				window.Modalytics.heap('identify', [window.modForm.opts.quadLinkParams.CLK]);
			});
		}
	}
}
